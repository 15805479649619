/* eslint-disable */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;900&family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

body,
*,
html {
  font-family: 'Mulish', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.font-ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

.font-abel {
  font-family: 'Abel', sans-serif;
}

:-moz-focusring {
  outline: none;
}

:focus {
  outline: none;
}

a {
  outline: none;
}

a:hover {
  outline: none;
}

.white-line::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: hsl(231, 100%, 93%);
}
.loader {
  border: 10px solid hsl(0, 0%, 66%); /* white */
  border-top: 10px solid hsl(233deg, 8%, 23%);
  border-bottom: 10px solid hsl(233deg, 8%, 23%);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}